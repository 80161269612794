<template>
	<div class="totalBox">
		<jy-query ref="checkForm" :model="formInline" label-width="90px">
			<jy-query-item label="查询类型" prop="type">
				<el-radio-group v-model="queryType">
					<el-radio label="0">线路</el-radio>
					<el-radio label="1">车辆</el-radio>
				</el-radio-group>
			</jy-query-item>
			<!-- <jy-query-item prop="routeId" label="线路">
				<el-select v-model="routeIds" @change="routeChange">
					<el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
				</el-select>
			</jy-query-item> -->
			<jy-query-item label="查询方式" prop="type">
				<el-radio-group v-model="formInline.type">
					<el-radio label="1">月</el-radio>
					<el-radio label="0">年</el-radio>
				</el-radio-group>
			</jy-query-item>
			<jy-query-item label="" prop="queryYear" v-if="formInline.type == '0' && isshow">
				<el-date-picker
					v-model="formInline.queryYear"
					:clearable="false"
					type="year"
					value-format="yyyy"
					placeholder="选择年"
				></el-date-picker>
			</jy-query-item>
			<jy-query-item label="" prop="queryMonth" v-if="formInline.type == '1' && isshow">
				<el-date-picker
					v-model="formInline.queryMonth"
					:clearable="false"
					type="month"
					value-format="yyyy-MM"
					placeholder="选择月"
				></el-date-picker>
			</jy-query-item>
			<jy-query-item label="选择车辆" prop="vehicleIds" :span="2" v-if="queryType == '1' && isshow">
				<el-input v-model="formInline.vehicleNames" placeholder="选择车辆" readonly @focus="chooseCar"></el-input>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('list') || true">查询</el-button>
				<el-button type="primary" plain @click="resetForm('checkForm')" v-if="btnexist('reset') || true">重置</el-button>
			</template>
			<template slot="operate">
				<el-button type="primary" @click="onexport" v-if="btnexist('export') || true">导出</el-button>
				<div style="flex: 1;"></div>
				<div style="align-items: center;display: flex;font-size: 14px;">总客流：<span style="color: #2A73FF;">{{totalNum}}</span> 人</div>
			</template>
		</jy-query>
		<jy-table style="max-height: 645px" ref="jyTable" :data="dataList" >
			<jy-table-column type="index" label="序号" width="60" fixed align="center"></jy-table-column>
			<jy-table-column prop="routeNa" label="线路名称" min-width="160" fixed v-if="queryType == '0'"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" min-width="160" fixed v-if="queryType == '1'"></jy-table-column>
			<jy-table-column prop="routeNa" label="所属线路" min-width="160" fixed v-if="queryType == '1'"></jy-table-column>
			<jy-table-column prop="total" label="总客流" min-width="100" fixed></jy-table-column>
			<jy-table-column :prop="item" :label="item" min-width="70" v-for="item in propList" :key="item"></jy-table-column>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<car-selectbatch ref="carSelectbatch" @choose="chooseCarList"></car-selectbatch>
	</div>
</template>
<script>
import carSelectbatch from "@/components/pages/admin/resourcesAllocation/carDriver/carSelectbatch";
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			isshow: true,
			formInline: {
				routeId: undefined,
				customNo: undefined,
				vehicleIds: undefined,
				vehicleNames: undefined,
				type: "1",
				queryYear: undefined,
				queryMonth: undefined
			},
			queryType: "0",
			pageSize: 10,
			total: 100,
			totalNum:0,
			pageIndex: 1,
			dataList: [],
			routeList: [],
			carList: [],
			propList: [],
			routeIds: [],

			btnMenuId: "scheduleStatisticalAnalysisGPStotal"
		};
	},
	watch: {
		"formInline.type"(val) {
			console.log(val);
			this.isshow = false;
			this.$nextTick(() => {
				this.isshow = true;
			});
			this.oncheck();
		},
		queryType() {
			// 调用查询
			this.oncheck();
		}
	},
	mixins: [btnMixins],
	components: {
		carSelectbatch
	},
	activated() {},
	async created() {
		this.queryType = "0";
		this.getNowData();
		// await this.getRouteList();
		this.getList();
	},

	methods: {
		getNowData() {
			let date = new Date();
			let year = date.getFullYear();
			let month = date.getMonth();
			if (month == 0) {
				year -= 1;
				month = 12;
			}
			month = month < 10 ? "0" + month : month;
			this.formInline.queryYear = new Date().Format("yyyy");
			this.formInline.queryMonth = year + "-" + month;
			console.log("--------month");
		},
		// async getRouteList() {
		// 	let url = "/route/info/queryRouteInfoByOrgIds";
		// 	await this.$http.post(url).then(({ detail }) => {
		// 		this.routeList = detail;
		// 		// this.formInline.routeId = this.routeList[0].routeId
		// 		// if(this.routeList.length){
		// 		//     this.formInline.routeId = this.routeList[0].routeId
		// 		// }
		// 	});
		// },
		getList() {
			let url = "/stat/passenger/analysis/queryRoute";
			if (this.queryType == "1") {
				url = "/stat/passenger/analysis/queryVehicle";
			}
			let option = {
				...this.formInline,
				pageSize: this.pageSize,
				pageIndex: this.pageIndex
			};
			if (this.formInline.type == 1) {
				option.date = this.formInline.queryMonth;
			} else {
				option.date = this.formInline.queryYear;
			}
			Object.keys(option).forEach(key => {
				if (/^\s*$/.test(option[key]) || option[key] === undefined) {
					delete option[key];
				}
			});
			this.$http.post(url, option).then(({ detail }) => {
				this.dataList = detail.list;
				console.log("-------数据", this.dataList);
				if (this.dataList.length) {
					this.propList = this.dataList[0].detailVoList.map(item => {
						let list = item.date.split("-");
						if (this.formInline.type == 1) {
							return list[list.length - 1];
						} else {
							return list[list.length - 1];
						}
					});
					this.propList.sort((a, b) => {
						return Number(a) - Number(b);
					});
					this.propList = this.propList.map(item => {
						if (this.formInline.type == 1) {
							return item + "号";
						} else {
							return item + "月";
						}
					});
				} else {
					this.propList = [];
				}
				this.dataList.forEach(item => {
					item.detailVoList.forEach(one => {
						let list = one.date.split("-");
						let prop = "";
						if (option.type == 1) {
							prop = list[list.length - 1] + "号";
						} else {
							prop = list[list.length - 1] + "月";
						}
						item[prop] = one.total;
					});
				});
				console.log("-------数据propList", this.propList);
				this.total = detail.total;
				this.totalNum = detail.sum;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.carList = [];
			this.formInline.vehicleNames = "";
			this.queryType = "0";
			this.formInline.routeId = "";
			this.formInline.type = "1";
			this.formInline.customNo = "";
			this.routeIds = [];
			// if(this.routeList.length){
			//     this.formInline.routeId = this.routeList[0].routeId
			// }
			this.getNowData();
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		routeChange() {
			this.formInline.routeId = this.routeIds.toString();
		},
		chooseCar() {
			this.$refs.carSelectbatch.init(this.carList);
		},
		chooseCarList(list) {
			this.carList = list;
			this.formInline.vehicleNames = list.map(item => item.plateNum).join(",");
			this.formInline.vehicleIds = list.map(item => item.vehicleId).join(",");
		},
		//导出
		onexport() {
			let url = "/ischedule/stat/passenger/analysis/exportRoute";
			if (this.queryType == "1") {
				url = "/ischedule/stat/passenger/analysis/exportVehicle";
			}
			let option = {
				...this.formInline
			};
			if (this.formInline.type == 1) {
				option.date = this.formInline.queryMonth;
			} else {
				option.date = this.formInline.queryYear;
			}
			this.$http.post(url, option, { type: "json", isExcel: true, baseType: "base" }).then(res => {
				this.$util.funDownload(res.data, "客流分析统计");
			});
		},
		getSummaries(param) {
			console.log("getSummaries:", param);
			const { columns } = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = "合计";
					return;
				} else {
					if (column.property == "total") {
						sums[index] = this.totalNum;
						return;
					} else {
						sums[index] = "";
						return;
					}
				}
			});
			return sums;
		}
	}
};
</script>
<style lang="scss"></style>
